import apiService from "@/http/apiService";

const claims = {
  namespaced: true,

  state: {
    membershipNoFilter: "",
    firstNameClaimsFilter: "",
    email: {},
    addMember: {},
    updateClaim: {},
    bulkMembers: {},
    bulkMemberDetails: {},
    claims: [],
    communicationByMemberId: [],
    claimsByMembershipNo: {},
    claimsByMemberId: {},
    claimsByState: [],
    clickedClaimId: null,
    clickedCommunicationId: null,
    usersByRole: [],
    memberDetails: [],
    addNewClaim: {},
    addAuditEvent: [],
    communicationByCommunicationId: [],
    users: [],
    claimTypes: [],
    allClaimsByMembershipNo: [],
    guidId: "",
    claimsByGuidId: [],
    pregeneratedDocs: [],
  },

  mutations: {
    setPregeneratedDocs(state, data) {
      state.pregeneratedDocs = data;
    },
    setAllClaimsByMembershipNo(state, data) {
      state.allClaimsByMembershipNo = data;
    },
    setFetchClaimTypes(state, data) {
      state.claimTypes = data;
    },

    // setFirstNameClaimsFilter
    setFirstNameClaimsFilter(state, data) {
      state.firstNameClaimsFilter = data;
    },

    // setFetchUsers
    setFetchUsers(state, data) {
      state.users = data;
    },

    // setFetchCommunicationByCommunicationId
    setFetchCommunicationByCommunicationId(state, data) {
      state.communicationByCommunicationId = data;
    },

    // setBulkMembers
    setBulkMembers(state, data) {
      state.bulkMembers = data;
    },

    // setBulkMemberDetails
    setBulkMemberDetails(state, data) {
      state.bulkMemberDetails = data;
    },

    // setMembershipNoFilter
    setMembershipNoFilter(state, data) {
      state.membershipNoFilter = data;
    },

    // setEmail
    setEmail(state, data) {
      state.email = data;
    },

    // setAdd Memeber
    setAddMember(state, data) {
      state.addMember = data;
    },

    // setUpdateClaim
    setUpdateClaim(state, data) {
      state.updateClaim = data;
    },

    // setClaims
    setClaims(state, data) {
      state.claims = data;
    },

    // setClaimsByMembershipNo
    setClaimsByMembershipNo(state, data) {
      state.claimsByMembershipNo = data;
    },

    // setClaimsByMemberId
    setClaimsByMemberId(state, data) {
      state.claimsByMemberId = data;
    },

    // setClaimsByState
    setClaimsByState(state, data) {
      state.claimsByState = data;
    },

    // setClaimId
    setClaimId(state, data) {
      state.clickedClaimId = data;
    },

    setCommunicationId(state, data) {
      state.communicationId = data;
    },

    // setCommunicationByMemberId
    setCommunicationByMemberId(state, data) {
      state.communicationByMemberId = data;
    },

    // setUsersByRole
    setUsersByRole(state, data) {
      state.usersByRole = data;
    },

    setAddNewClaim(state, data) {
      state.addNewClaim = data;
    },

    // setMembershipDetails
    setMemberDetailsByMembershipNumber(state, data) {
      state.memberDetails = data;
    },

    setAddAuditEvent(state, data) {
      state.addAuditEvent = data;
    },

    // setGuidId
    setGuidId(state, data) {
      state.guidId = data;
    },

    // setClaimsByGuidId
    setClaimsByGuidId(state, data) {
      state.claimsByGuidId = data;
    },

    resetState(state) {
      state.membershipNoFilter = "";
      state.email = {};
      state.addMember = {};
      state.updateClaim = {};
      state.bulkMembers = {};
      state.bulkMemberDetails = {};
      state.claims = [];
      state.communicationByMemberId = [];
      state.claimsByMembershipNo = {};
      state.claimsByMemberId = {};
      state.claimsByState = [];
      state.clickedClaimId = 0;
      state.usersByRole = [];
      state.memberDetails = [];
      state.addNewClaim = {};
      state.addAuditEvent = [];
      state.users = [];
      state.firstNameClaimsFilter = "";
    },
  },

  actions: {
    // addBulkMembers
    async addBulkMembers({ commit }, data) {
      try {
        const response = await apiService.addBulkMembers(data);
        commit("setBulkMembers", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addBulkMembers", error);
      }
    },

    // Add New Claim
    async addNewClaim({ commit }, data) {
      try {
        const response = await apiService.addNewClaim(data);
        commit("setAddNewClaim", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addNewClaim", error);
      }
    },

    // sendEmail
    async sendEmail(_, data) {
      try {
        const response = await apiService.sendEmail(data);
        // commit("setEmail", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("sendEmail", error);
      }
    },

    // getCommunicationByMemberId
    async getCommunicationByMemberId({ commit }, memberClaimId) {
      try {
        const response = await apiService.getCommunicationByMemberId(
          memberClaimId
        );
        commit("setCommunicationByMemberId", response.data);
      } catch (error) {
        console.log("setCommunicationByMemberId", error);
      }
    },

    // addMemeber
    async addMember({ commit }, data) {
      try {
        const response = await apiService.addMember(data);
        commit("setAddMember", response.data);

        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addMemeber", error);
      }
    },

    // updateClaim
    async updateClaim(_, data) {
      try {
        const response = await apiService.updateClaim(data);
        // commit("setUpdateClaim", response.data);

        return response;
      } catch (error) {
        console.log("updateClaim", error);
      }
    },

    // getClaims
    async getClaims({ commit }) {
      try {
        const response = await apiService.getClaims();
        commit("setClaims", response.data);
        return response.data
      } catch (error) {
        console.log("getClaims", error);
      }
    },

    async getClaimsToAddClaims() {
      try {
        const response = await apiService.getClaims();
        return response.data
      } catch (error) {
        console.log("getClaimsToAddClaims", error);
      }
    },

    // getClaimsByMemberId
    async getClaimsByMemberId({ commit }, memberClaimId) {
      try {
        const response = await apiService.getClaimByMemberId(memberClaimId);

        commit("setClaimsByMemberId", response.data);

        return response;
      } catch (error) {
        console.log("getClaimsByMemberId", error);
      }
    },

    // getClaimByMembershipNo
    async getClaimByMembershipNo({ commit }, membershipNo) {
      try {
        const response = await apiService.getClaimByMembershipNo(membershipNo);

        commit("setClaimsByMembershipNo", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("getClaimByMembershipNo", error);
      }
    },

    // getMemberClaimsByGuidId
    async getMemberClaimsByGuidId({ commit }, guidId) {
      try {
        const response = await apiService.getMemberClaimsByGuidId(guidId);
        commit("setClaimsByGuidId", response.data);
      } catch (error) {
        console.log("getMemberClaimsByGuidId", error);
      }
    },

    // Get Member details by membership number
    async getMemberDetailsByMembershipNumber({ commit }, membershipNumber) {
      try {
        const response = await apiService.getMemberDetails(membershipNumber);
        commit("setMemberDetailsByMembershipNumber", response.data);

        return response;
      } catch (error) {
        console.log("getMemberDetails", error);
      }
    },
    async getMemberDetailsByMembershipNumberToAddClaims(_, membershipNumber) {
      try {
        const response = await apiService.getMemberDetails(membershipNumber);
        return response;
      } catch (error) {
        console.log("getMemberDetails", error);
      }
    },

    // getClaimsByState
    async getClaimsByState({ commit }, { claimState, workFlowId }) {
      try {
        const response = await apiService.getClaimsByState(
          claimState,
          workFlowId
        );
        commit("setClaimsByState", response.data);
      } catch (error) {
        console.log("getClaimsByState", error);
      }
    },

    // getUsersByRole
    async getUsersByRole({ commit }, role) {
      try {
        const response = await apiService.getUsersbyrole(role);
        commit("setUsersByRole", response.data);
      } catch (error) {
        console.log("getUsersByRole", error);
      }
    },

    async addAuditEvent(_, data) {
      try {
        const response = await apiService.addAuditEvent(data);
        // commit("setAddAuditEvent", response.data);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        console.log("addAuditEvent", error);
      }
    },

    async updateMember(_, data) {
      try {
        const response = await apiService.updateMember(data);
        // commit("setUpdateMember", response.data);

        return response;
      } catch (error) {
        console.log("updateMember", error);
      }
    },
    async updateMemberClaim(_, data) {
      try {
        const response = await apiService.updateMemberClaim(data);
        // commit("setUpdateMemberClaim", response.data);

        return response;
      } catch (error) {
        console.log("updateMemberClaim", error);
      }
    },
    async fetchCommunicationByCommunicationId({ commit }, id) {
      try {
        const response = await apiService.fetchCommunicationByCommunicationId(
          id
        );

        commit("setFetchCommunicationByCommunicationId", response.data);
        return response;
      } catch (error) {
        console.log("fetchCommunicationByCommunicationId", error);
      }
    },
    async sendSMS(_, data) {
      try {
        const response = await apiService.sendSMS(data);

        return response;
      } catch (error) {
        console.log("sendSMS", error);
      }
    },
    async fetchUsers({ commit }, data) {
      try {
        const response = await apiService.fetchUsers(data);
        commit("setFetchUsers", response.data);
        return response;
      } catch (error) {
        console.log("fetchUsers", error);
      }
    },
    async fetchClaimTypes({ commit }, id) {
      try {
        const response = await apiService.fetchClaimTypes(id);
        commit("setFetchClaimTypes", response.data);
        return response;
      } catch (error) {
        console.log("fetchClaimTypes", error);
      }
    },
    async sendGenerateddocEmail(_, data) {
      try {
        const response = await apiService.sendGenerateddocEmail(data);
        return response;
      } catch (error) {
        console.log("sendGenerateddocEmail", error);
      }
    },
    async fetchAllClaimsByMembershipNo({ commit }, membershipnumber) {
      try {
        const response = await apiService.fetchAllClaimsByMembershipNo(
          membershipnumber
        );
        commit("setAllClaimsByMembershipNo", response.data);
        return response;
      } catch (error) {
        console.log("fetchAllClaimsByMembershipNo", error);
      }
    },
    async fetchPregeneratedDoc(_, { memberClaimId, recipient }) {
      try {
        const response = await apiService.fetchPregeneratedDoc(
          memberClaimId,
          recipient
        );
        return response;
      } catch (error) {
        console.log("fetchPregeneratedDoc", error);
      }
    },
    async fetchPregeneratedDocs({ commit }, memberClaimId) {
      try {
        const response = await apiService.fetchPregeneratedDocs(memberClaimId);
        commit("setPregeneratedDocs", response.data);
        return response;
      } catch (error) {
        console.log("fetchPregeneratedDocs", error);
      }
    },
  },

  getters: {
    getPregeneratedDocs(state) {
      return state.pregeneratedDocs;
    },
    getAllClaimsByMembershipNo(state) {
      return state.allClaimsByMembershipNo;
    },
    getClaimTypes(state) {
      return state.claimTypes;
    },
    // getFirstNameClaimsFilter
    getFirstNameClaimsFilter(state) {
      return state.firstNameClaimsFilter;
    },

    // getUsers
    getUsers(state) {
      return state.users;
    },
    getcommunicationByCommunicationId(state) {
      return state.communicationByCommunicationId;
    },
    // getBulkMembers
    getBulkMembers(state) {
      return state.bulkMembers;
    },

    // getBulkMemberDetails
    getBulkMemberDetails(state) {
      return state.bulkMemberDetails;
    },

    // getMembershipNoFilter
    getMembershipNoFilter(state) {
      return state.membershipNoFilter;
    },

    // getCommunicationByMemberId
    getCommunicationByMemberId(state) {
      return state.communicationByMemberId;
    },

    // getClaims
    getClaims(state) {
      return state.claims;
    },

    // getClaimsByMemberId
    getClaimsByMemberId(state) {
      return state.claimsByMemberId;
    },

    // getClaimsByMembershipNo
    getClaimsByMembershipNo(state) {
      return state.claimsByMembershipNo;
    },

    // getClaimsByState
    getClaimsByState(state) {
      return state.claimsByState;
    },

    // getClaimId
    getClaimId(state) {
      return state.clickedClaimId;
    },

    getCommunicationId(state) {
      return state.communicationId;
    },
    // getUsersByRole
    getUsersByRole(state) {
      return state.usersByRole;
    },

    // getMemberDetails
    getMemberDetails(state) {
      return state.memberDetails;
    },

    // getGuidId
    getGuidId(state) {
      return state.guidId;
    },

    // getMemberClaimsByGuidId
    getMemberClaimsByGuidId(state) {
      return state.claimsByGuidId;
    },
  },
};

export default claims;
